import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";

// routes config
import routes from "../../routes/Router";
import { useContext } from "react";
import { AuthContext } from "../../helpers/AuthContext";
const TeacherAppContent = () => {
  const { authState, setAuthState } = useContext(AuthContext);

  const [user, setuser] = useState(null);

  useEffect(() => {
    setuser(localStorage.getItem("user"));
  }, []);

  return (
    <CContainer fluid>
      <Suspense fallback={<CSpinner color="primary" />}>
        <AuthContext.Provider value={{ authState, setAuthState }}>
          <Routes>
            {routes.map((route, idx) => {
              // authState.role
              return (
                localStorage.getItem("user") == "teacher" &&
                route.role == "teacher" && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              );
            })}
            {/* ({localStorage.getItem("user") == "teacher" && (<Route path="*" element={<Navigate to="/teacher/dashboard" replace />} />)}) */}
            (
            {localStorage.getItem("user") == "teacher" && (
              <Route
                path="*"
                element={
                  <Navigate to="teacher/behaviour/leaderboard" replace />
                }
              />
            )}
            )
          </Routes>
        </AuthContext.Provider>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(TeacherAppContent);
