import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SchoolResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [touchedFields, setTouchedFields] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setTouchedFields({ ...touchedFields, [name]: true });
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setTouchedFields({
      newPassword: true,
      confirmPassword: true,
    });

    const valid = formData.newPassword && formData.confirmPassword;
    const passwordsMatch = formData.newPassword === formData.confirmPassword;
    const newPasswordValid = formData.newPassword.length >= 8;

    if (!valid || !passwordsMatch || !newPasswordValid) {
      setValidated(true);
      return;
    }

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/schools/resetPassword/${token}`,
        {
          newPassword: formData.newPassword,
        }
      );
      alert(response.data.message);
      localStorage.removeItem("accessToken");
      // navigate("/school/admin");
      navigate(`/school/admin/${localStorage.getItem("schoolId")}`);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        console.error("Reset failed:", errorMessage);
        if (formData.newPassword && formData.confirmPassword) {
          alert(errorMessage);
        }
      } else {
        console.error("Reset failed:", error.message);
      }
    }
  };

  const passwordsMatch = formData.newPassword === formData.confirmPassword;
  const newPasswordValid = formData.newPassword.length >= 8;
  const isEmpty = (field) => !formData[field];

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm
                    className="needs-validation"
                    noValidate
                    validated={validated}
                    onSubmit={handleResetPassword}
                  >
                    <h2>Reset Password</h2>

                    <CInputGroup className="mb-4 mt-4">
                      <CInputGroupText></CInputGroupText>
                      <CFormInput
                        type={showPassword ? "text" : "password"}
                        id="newPassword"
                        name="newPassword"
                        required
                        value={formData.newPassword}
                        placeholder="New Password"
                        onChange={handleChange}
                        className={
                          touchedFields.newPassword && !newPasswordValid
                            ? "is-invalid"
                            : touchedFields.newPassword && newPasswordValid
                            ? "is-valid"
                            : ""
                        }
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-0 flex items-center pr-3 bg-transparent border border-light"
                      >
                        {showPassword ? (
                          <FaEye className="h-5 w-5 text-gray-500" />
                        ) : (
                          <FaEyeSlash className="h-5 w-5 text-gray-500" />
                        )}
                      </button>

                      {touchedFields.newPassword && !newPasswordValid && (
                        <div
                          className="invalid-feedback mt-2"
                          style={{
                            position: "absolute",
                            bottom: "-1.5rem",
                            left: 0,
                            width: "100%",
                          }}
                        >
                          Password must be at least 8 characters long
                        </div>
                      )}
                    </CInputGroup>

                    <CInputGroup className="mb-4">
                      <CInputGroupText></CInputGroupText>
                      <CFormInput
                        type={showCPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className={
                          touchedFields.confirmPassword && !passwordsMatch
                            ? "is-invalid"
                            : touchedFields.confirmPassword && passwordsMatch
                            ? "is-valid"
                            : ""
                        }
                      />
                      <button
                        type="button"
                        onClick={() => setShowCPassword(!showCPassword)}
                        className="absolute inset-y-0 right-0 flex items-center pr-3 bg-transparent border border-light"
                      >
                        {showCPassword ? (
                          <FaEye className="h-5 w-5 text-gray-500" />
                        ) : (
                          <FaEyeSlash className="h-5 w-5 text-gray-500" />
                        )}
                      </button>

                      {touchedFields.confirmPassword && !passwordsMatch && (
                        <div
                          className="invalid-feedback  mt-2"
                          style={{
                            position: "absolute",
                            bottom: "-1.5rem",
                            left: 0,
                            width: "100%",
                          }}
                        >
                          Passwords do not match
                        </div>
                      )}
                    </CInputGroup>

                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" type="submit">
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5"
                style={{ width: "44%" }}
              ></CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default SchoolResetPassword;
