import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Checkbox, Table, Space } from "antd";
import {
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import { notification } from "antd";
import { RotatingLines } from "react-loader-spinner";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as XLSX from "xlsx"; // Add XLSX for exporting to Excel

import { saveAs } from "file-saver";
// import CouponList from "../CouponList/List";
function Template() {
  const token = localStorage.getItem("accessToken");
  const Teacher_id = localStorage.getItem("userId");
  const [couponsList, setCouponsList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [loading, setLoading] = useState(false);

  const [collecIds, setcollecIds] = useState([]);
  const [leaveType, setLeaveType] = useState("");

  const [filterStatus, setFilterStatus] = useState("");
  const [moduleName, setModuleName] = useState([]);

  const [dateRange, setdateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const navigate = useNavigate();

  const [modules, setModules] = useState([]);

  const getModules = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/behaviorpoint/modules`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      console.log(response, "response");
      if (response.data?.status == "success") {
        setModules(response?.data?.data?.data?.docs);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  useEffect(() => {
    getModules();
  }, []);

  const getCouponList = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/notification-template`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log("dd -> this is a response ", res);

      setCouponsList(res?.data?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (startDate) {
      if (endDate) {
        getCouponList();
      }
    } else {
      getCouponList();
    }
  }, [dateRange, filterStatus, leaveType]);

  const toggleSelectAll = () => {
    setcollecIds(selectAll ? [] : couponsList.map((student) => student._id));
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (id) => {
    setcollecIds((prev) =>
      prev.includes(id)
        ? prev.filter((selectedId) => selectedId !== id)
        : [...prev, id]
    );
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Module",
      dataIndex: "module",
    },
    {
      title: "Applicable For",
      render: (_, record) => record.leave_type,
      // sorter: (a, b) => a.term.localeCompare(b.term),
    },

    {
      title: "Condition",
      dataIndex: "condition",
    },
    {
      title: "Remark",
      dataIndex: "remark",
    },

    {
      title: "Action",
      render: (_, record) => (
        <>
          <button
            className=" btn btn-outline-primary"
            onClick={() =>
              navigate("/school/Notification/template/edit", {
                state: { Id: record._id },
              })
            }
          >
            {" "}
            Edit{" "}
          </button>
        </>
      ),
    },
  ];

  const withdrawlRequest = async (id) => {
    console.log("collect is", collecIds);
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/attendance/leave-request/withdraw`,
        {
          ids: collecIds.length > 0 ? collecIds : [id],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      notification.success({
        message: "Success",
        description: "Status changed successfully!",
      });
      getCouponList();
      setcollecIds([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const [requestData, setRequestData] = useState({
    is_issued: true,
    remark: "",
    userId: "",
  });

  const handleApprove = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/behaviorpoint/coupon-approval/${requestData?.userId}`,
        {
          is_issued: true,
          remark: requestData?.remark,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (res?.data?.status == "success") {
        notification.success({
          message: "Success",
          description: res?.data?.message,
        });
        getCouponList();
        setVisible(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "error",
        description: error?.message,
      });
    }
  };

  return (
    <div>
      <CContainer fluid>
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="RemarkModalLabel"
        >
          <CModalHeader>
            <CModalTitle id="RemarkModalLabel">Approval Remark</CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            <textarea
              className="form-control"
              rows="4"
              placeholder="Enter your remarks here..."
              value={requestData?.remark}
              onChange={(e) =>
                setRequestData({ ...requestData, remark: e.target.value })
              }
            ></textarea>
          </CModalBody>
          <CModalFooter className="pl-0">
            <button
              className="btn btn-outline-secondary ms-0 me-2"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => handleApprove()}>
              Approve
            </button>
          </CModalFooter>
        </CModal>

        <div className="mb-2">
          <div className="d-flex justify-content-between newmwnu mb-2">
            <h2>Notification Template Listing </h2>
          </div>
          <div className="filters d-flex gap-3 pb-2 align-items-center flex-wrap Teacher_Leave_mobile Assign_section">
            <div className="d-flex flex-column">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setdateRange(update);
                }}
                isClearable={true}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select Date Range"
              />
            </div>
            <select
              value={leaveType}
              onChange={(e) => {
                setLeaveType(e.target.value);
              }}
              style={{ width: "200px" }}
              className="form-control form-select"
            >
              <option value="">Applicable for</option>
              <option value="full-day">Student</option>
              <option value="teacher">Teacher</option>
              {/* <option value="early-release">Early-Realease</option> */}
            </select>

            <select
              value={moduleName}
              onChange={(e) => setModuleName(e.target.value)}
              style={{ width: "200px" }}
              className="form-control form-select"
            >
              <option value=""> Seleted module</option>

              <option value="Behaviour">Behaviour</option>

              <option value="attendance">attendance</option>
              <option value="proxy">proxy</option>
              <option value="homework">homework</option>
            </select>

            <button
              onClick={() => {
                setModuleName("");
                setdateRange([null, null]);
                setLeaveType("");
              }}
              className="btn btn-primary"
            >
              Reset Filter
            </button>
          </div>
        </div>

        {loading && (
          <div className="spinner-container">
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              strokeColor="#506EE4"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}

        <Table
          rowKey="_id"
          columns={columns}
          className="bordered-table"
          dataSource={couponsList}
          pagination={{
            defaultPageSize: 25,
            pageSizeOptions: ["25", "50", "100"],
            showSizeChanger: true,
            locale: {
              items_per_page: "",
            },
          }}
        />
      </CContainer>
    </div>
  );
}

export default Template;
