import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ allowedRoles }) => {
  // Check if the user is authenticated
  const jwtToken = localStorage.getItem("accessToken");
  const userType = localStorage.getItem("user");

  // If no JWT token or the user type isn't allowed, redirect to login page
  if (!jwtToken) {
    return <Navigate to="/" />;
  }

  // If authenticated and authorized, show the outlet (render the child route)
  return <Outlet />;
};

export default ProtectedRoute;
