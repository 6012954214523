import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import user1 from "../assets/images/users/user4.jpg";
import { AuthContext } from "../helpers/AuthContext";
import { CToast, CToastHeader, CToastBody } from "@coreui/react";

import { setDataLayout } from "../redux/themeSettingSlice";

import {
  setExpandMenu,
  setMobileSidebar,
  toggleMiniSidebar,
} from "../redux/sidebarSlice";

import { useContext, useEffect, useState } from "react";
import axios from "axios";

const SuperAdminHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [token] = useState(localStorage.getItem("accessToken"));

  const logout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("currentURL");
    localStorage.removeItem("imgUrl");
    navigate("/admin");
    return;

    const responses = await axios
      .post(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params: {
          //   class: res.class,
          //   repetation: "weekly",
          //   applicableTo: res.gender,
          //   currentTime: new Date(),
          // },
        }
      )
      .then(function (response) {
        console.log(response.data.status);
        if (response.data.status == "success") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          navigate("/");
        }
      });
  };

  const mobileSidebar = useSelector(
    (state) => state.sidebarSlice.mobileSidebar
  );

  const toggleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };

  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  const handleToggleMiniSidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div
          className="header-left active"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Link className="logo logo-normal">
            <img
              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
              src="assets/img/logo.svg"
              alt="Logo"
            />
          </Link>
          <Link className="logo-small">
            <img
              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
              src="assets/img/logo-small.svg"
              alt="Logo"
            />
          </Link>
          <Link className="dark-logo">
            <img
              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
              src="assets/img/logo.svg"
              alt="Logo"
            />
          </Link>

          <Link id="toggle_btn" to="#" onClick={handleToggleMiniSidebar}>
            <i className="ti ti-menu-deep" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#sidebar"
          onClick={handleToggleMiniSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        <div className="header-user">
          <div className="nav user-menu">
            <div className="nav-item nav-search-inputs me-auto">
              <span class=" d-flex align-items-center bg-white rounded pright-3 text-dark fw-bold fs-4">
                Super Admin
              </span>
            </div>

            {/* /Search */}
            <div className="d-flex align-items-center">
              <div className="dropdown-menu dropdown-menu-end notification-dropdown p-4">
                <div className="noti-content">
                  <div className="d-flex flex-column">
                    <div className="border-bottom mb-3 pb-3">
                      <Link>
                        <div className="d-flex">
                          <span className="avatar avatar-lg me-2 flex-shrink-0">
                            <img
                              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
                              src="assets/img/profiles/avatar-27.jpg"
                              alt="Profile"
                            />
                          </span>
                          <div className="flex-grow-1">
                            <p className="mb-1">
                              <span className="text-dark fw-semibold">
                                Shawn
                              </span>{" "}
                              performance in Math is below the threshold.
                            </p>
                            <span>Just Now</span>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="border-0 mb-3 pb-0">
                      <Link>
                        <div className="d-flex">
                          <span className="avatar avatar-lg me-2 flex-shrink-0">
                            <img
                              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
                              src="assets/img/profiles/avatar-01.jpg"
                              alt="Profile"
                            />
                          </span>
                          <div className="flex-grow-1">
                            <p className="mb-1">
                              A new teacher record for{" "}
                              <span className="text-dark fw-semibold">
                                Elisa
                              </span>
                            </p>
                            <span>09:45 AM</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="d-flex p-0">
                  <Link to="#" className="btn btn-light w-100 me-2">
                    Cancel
                  </Link>
                  <Link className="btn btn-primary w-100">View All</Link>
                </div>
              </div>

              <div className="dropdown ms-1">
                <Link
                  to="#"
                  className="dropdown-toggle d-flex flex-column align-items-center"
                  data-bs-toggle="dropdown"
                >
                  <span className="avatar avatar-md">
                    <img
                      crossorigin={process.env.REACT_APP_DEV_BASE_URL}
                      src={user1}
                      alt="Img"
                      className="img-fluid rounded"
                    />
                  </span>
                </Link>
                <div className="dropdown-menu">
                  <div className="d-block">
                    <div className="d-flex align-items-center p-2">
                      <span className="avatar avatar-md me-2 online avatar-rounded">
                        <img
                          crossorigin={process.env.REACT_APP_DEV_BASE_URL}
                          src={user1}
                          alt="img"
                          className="img-fluid rounded-circle mright-20px"
                        />
                      </span>
                      <div>
                        <h6> Super Admin </h6>

                        {/* <p className="text-primary mb-0">Administrator</p> */}
                      </div>
                    </div>

                    <hr className="m-0" />
                    <button
                      className="dropdown-item d-inline-flex align-items-center p-2"
                      onClick={logout}
                    >
                      <i className="ti ti-login me-2" />
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminHeader;
